<template>
  <v-dialog
    :value="value"
    persistent
    width="100%"
    max-width="500"
    @input="$emit('input', $event)"
  >
    <v-card flat>
      <v-card-title class="text-h5 ap-orange--text">
        TOTP Secret Key
      </v-card-title>

      <v-divider />

      <div class="d-flex align-center pa-6">
        <span v-if="secret">
          <div>
            The new secret key is:
            <span class="font-weight-medium">{{ secret }}</span>
          </div>

          <div>Applying the new secret will require a new authentication.</div>
        </span>
        <span v-else>
          Are you sure you want to generate a new TOTP Secret Key?
        </span>
      </div>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="ap-orange" @click="cancel"> Cancel </v-btn>

        <v-btn v-if="secret" dark color="ap-orange" @click="applyNewSecret">
          Apply
        </v-btn>

        <v-btn v-else dark color="ap-orange" @click="generateNewSecret">
          Generate
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import { setTotpSecretKey } from '@/api/dev'
import { mapState } from 'vuex'

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      secret: null,
    }
  },
  computed: {
    ...mapState('auth', ['contextMessage']),
  },
  methods: {
    cancel() {
      this.secret = null
      this.$emit('input', false)
    },
    generateNewSecret() {
      this.secret = uuidv4().replaceAll('-', '')
    },
    async applyNewSecret() {
      await setTotpSecretKey(this.contextMessage.totpSecretKeyId, this.secret)
      this.$emit('sign-out')
    },
  },
}
</script>
