export default [
  {
    id: 'clean-data',
    label: 'CleanData',
    children: [
      {
        id: 'clean-data-admin-dashboard',
        icon: '$mdi-view-dashboard-outline',
        label: 'Dashboard',
        isInProduction: true,
        to: { name: 'root' },
      },
      {
        id: 'dev-dupegrid',
        icon: '$mdi-grid',
        label: 'Dupegrid',
        isInProduction: true,
        to: {
          name: 'dev-dupegrid',
        },
      },
      {
        id: 'rule-editor',
        icon: '$mdi-playlist-edit',
        label: 'Rule Editor',
        isInProduction: false,
        to: {
          name: 'rule-editor',
        },
      },
      {
        id: 'clean-data-admin',
        icon: '$mdi-cog-outline',
        label: 'Admin',
        wip: true,
        to: { name: 'admin-home' },
      },
    ],
  },
  {
    id: 'clean-entry',
    label: 'CleanEntry',
    children: [
      {
        id: 'clean-entry-admin-dashboard',
        icon: '$mdi-view-dashboard-outline',
        label: 'Dashboard',
        onlyFE: true,
        to: { name: 'on-entry-dashboard' },
      },
      {
        id: 'on-entry',
        icon: '$mdi-text-box-search-outline',
        label: 'Widget',
        onlyFE: true,
        to: { name: 'on-entry-find-duplicates' },
      },
    ],
  },
  {
    id: 'clean-verify',
    label: 'CleanVerify',
    children: [
      {
        id: 'clean-verify',
        icon: '$mdi-view-dashboard-outline',
        label: 'Dashboard',
        poc: true,
        to: { name: 'clean-verify-dashboard' },
      },
      {
        id: 'clean-verify-address-verification-widget',
        icon: '$mdi-map-marker-outline',
        label: 'Address Verification',
        poc: true,
        to: { name: 'clean-verify-address-verification-widget' },
      },
    ],
  },
  {
    id: 'others',
    label: 'Others',
    children: [
      {
        id: 'sales_rep',
        icon: '$mdi-view-dashboard-outline',
        label: 'Sales Rep',
        deprecated: true,
        to: { name: 'root/sales-rep-dashboard' },
      },
      {
        id: 'sales_manager',
        icon: '$mdi-view-dashboard-outline',
        label: 'Sales Manager',
        deprecated: true,
        to: { name: 'root/sales-manager-dashboard' },
      },
    ],
  },
  {
    id: 'system',
    label: 'System Configurations',
    children: [
      {
        id: 'totp-secret',
        icon: '$mdi-account-key-outline',
        label: 'Generate TOTP secret',
        isInProduction: false,
        event: 'generate-totp-secret',
      },
    ],
  },

  // {
  //   id: 'merge-history',
  //   label: 'Merge History',
  //   isInProduction: true,
  //   to: {
  //     name: 'merge-history',
  //     query: {
  //       entity_key: '0013t00001jVIprAAG',
  //       entity_name: 'Account',
  //     },
  //   },
  // },
]
