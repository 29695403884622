<template>
  <v-card flat>
    <v-card-title class="text-h5 ap-orange--text"> Features </v-card-title>

    <v-divider />

    <div class="px-4 py-6">
      <v-row>
        <v-col
          v-for="group in features"
          :key="group.id"
          cols="12"
          sm="6"
          class="py-1"
        >
          <v-list dense class="pa-0">
            <v-subheader>{{ group.label }}</v-subheader>
            <v-list-item
              v-for="feature in group.children"
              :key="feature.id"
              :to="feature.to"
              exact
              @click="onFeatureSelected(feature)"
            >
              <v-list-item-icon class="mr-2">
                <v-icon>{{ feature.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ feature.label }}
                </v-list-item-title>
              </v-list-item-content>

              <v-chip v-if="feature.isInProduction" color="success" x-small>
                live
              </v-chip>

              <v-chip v-if="feature.wip" color="info" x-small> WIP </v-chip>

              <v-chip v-if="feature.deprecated" color="error" dark x-small>
                deprecated
              </v-chip>

              <v-chip v-if="feature.poc" color="accent" x-small> POC </v-chip>

              <v-chip v-if="feature.onlyFE" color="warning" x-small>
                only FE
              </v-chip>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>

      <DevGenerateTotpSecretDialog
        v-model="isGenerateTotpSecretDialogVisible"
        @sign-out="clear"
      />
    </div>

    <v-divider />

    <v-card-actions>
      <v-spacer />
      <v-btn text color="ap-orange" @click="clear">
        <v-icon left> $mdi-logout-variant </v-icon>
        Sign Out
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import DevGenerateTotpSecretDialog from './DevGenerateTotpSecretDialog'
import features from './features'

export default {
  components: {
    DevGenerateTotpSecretDialog,
  },
  data() {
    return {
      isGenerateTotpSecretDialogVisible: false,
      dialog: false,
      features,
      secret: null,
    }
  },
  methods: {
    onFeatureSelected(feature) {
      this.$emit('feature-selected', feature)

      if (feature.event === 'generate-totp-secret') {
        this.isGenerateTotpSecretDialogVisible = true
      }
    },
    clear() {
      localStorage.removeItem('env')
      localStorage.removeItem('authJson')
      localStorage.removeItem('is_new_dupegrid_enabled')
      this.$router
        .push({ name: 'dev-root', query: { reset: true } })
        .catch(() => {})
      this.$emit('close')
    },
  },
}
</script>
